<template>
  <v-footer class="footer" bottom fixed padless color="transparent">
    <v-card class="flex" color="secondary" >
      <v-container color="primary" class="primary--text">
        <v-row dense no-gutters align="center" justify="center">
          <v-col dense>
            <router-link to="/">
              <v-img contain max-width='95%'
              src='../../../public/images/pratka_logo.svg'
              ></v-img>
            </router-link>

          <v-divider vertical style="margin-right:10px;"></v-divider>

          <!-- <v-menu v-model="languageMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="text-capitalize" v-bind="attrs" v-on="on" text>
              <v-icon left>mdi-translate</v-icon>
              {{ activeLang }}
              <v-icon small right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="(lang, index) in langs" :key="index" @click="handleMenuItemClick(lang)">
              <v-list-item-title>{{ lang.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

          </v-col>

          <!-- <v-col dense cols="12" xs="12" sm="12" md="6" lg="6" xs12 sm12 md6>
            <v-row cols='12' align='center' dense>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col cols='2'>
                    <v-icon color="primary" size="25px">mdi-clock</v-icon>
                  </v-col>
                  <v-col cols='10'>
                    <h5>{{ $vuetify.lang.t('$vuetify.footer.support.title') }}</h5>
                    {{ $vuetify.lang.t('$vuetify.footer.support.subtitle') }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col cols='2'>
                    <v-icon color="primary" size="25px">mdi-phone</v-icon>
                  </v-col>
                  <v-col cols='10'>
                    <h5>{{ $vuetify.lang.t('$vuetify.footer.phone.title') }}</h5>
                    <a :href="$vuetify.lang.t('$vuetify.home.contacts.phoneto')">{{ $vuetify.lang.t('$vuetify.footer.phone.subtitle') }}</a>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                <v-row>
                  <v-col cols='2'>
              <v-icon color="primary" size="25px">mdi-mail</v-icon>
              </v-col>
              <v-col cols='10'>
                <h5>{{ $vuetify.lang.t('$vuetify.footer.email.title') }}</h5>
                <a :href="$vuetify.lang.t('$vuetify.home.contacts.emailto')">{{ $vuetify.lang.t('$vuetify.footer.email.subtitle') }}</a>
              </v-col>
                </v-row>
              </v-col>
              
            </v-row>
          </v-col> -->

          <v-spacer></v-spacer>

          <v-col dense align="center" justify="center">
            <v-row>
              <v-col dense align="center" justify="center" style="margin-bottom:-10px;">
                {{ $vuetify.lang.t('$vuetify.footer.follow_us') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                v-for="item in footer_icons"
                :key="item.name"
                :href="item.href"
                target="_blank"
                color="primary"
                class="mx-2"
                icon
              >
                <v-icon size="30px">
                  {{ item.icon }}
                </v-icon>
            </v-btn>
              </v-col>
            </v-row>
            <!-- <v-btn v-for='item in menu_options' 
            :key="item.name" link :to="item.href"
            text 
            style="margin-right:10px;"  class='text-md-button text-lg-button text-sm-body primary--text'
            >{{ item.name }}</v-btn> -->
            
            <!-- <v-btn link to="/login" 
            color="secondary"
            style="margin-right:10px;"  class='text-md-button text-lg-button text-sm-body white--text'
            >{{login_btn_text}}</v-btn> -->
          </v-col>
        </v-row>

        <!-- <v-row dense no-gutters align="center" justify="center" class="pa-1">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-btn
            text 
            color="primary"
            @click='license_pdf'>
              {{ $vuetify.lang.t('$vuetify.navigation.menu.options.license') }}
            </v-btn>
            <v-btn
            v-for='item in footer_sitemap' :key="item.name"
            link :to="item.href"
            text 
            color="primary"
            style="margin-right:5px;"
            >{{ $vuetify.lang.t(item.name) }}</v-btn>
          </v-col>
        </v-row> -->
        
        <!-- <v-row dense no-gutters align="center" justify="center" class="pa-0">
          <v-col cols="12" xs="12" sm="12" md="2" lg="2">
            {{ $vuetify.lang.t('$vuetify.footer.we_work_with') }}
          </v-col>
          <v-col v-for='item in footer_cardIcons' :key="item.name" cols="3" xs="4" sm="3" md="4" lg="2">
            <v-img
            :src="item.src"
            width="50px"
            height="50px"
            align='center'
            justify='center'
            contain
            style="margin-right:5px;"
            ></v-img>
          </v-col>
        </v-row> -->

      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Footer',

    data: () => ({
      // Footer
      footer_icons: [
      { name:"facebook", icon:"mdi-facebook", href:"https://www.facebook.com/www.Pratka.net"},
      { name:"instagram", icon:"mdi-instagram", href:"https://www.instagram.com/pratkabg_com/"},
      ],

      footer_sitemap: [
        { name: "$vuetify.navigation.menu.options.cookies", href:"/cookies"},
        { name: "$vuetify.navigation.menu.options.tos", href:"/terms-of-service"},
        { name: "$vuetify.navigation.menu.options.gdpr", href:"/gdpr"},
        { name: "$vuetify.navigation.menu.options.payments", href:"/payments" }
      ],

      footer_cardIcons: [
        { name: "1", src: require('../../assets/images/cardProviders/visa.png')},
        { name: "2", src: require('../../assets/images/cardProviders/visa_electron.png')},
        { name: "3", src: require('../../assets/images/cardProviders/mastercard.png')},
        { name: "4", src: require('../../assets/images/cardProviders/maestro.png')}
      ],
    }),
    
    methods: {
      async license_pdf() {
        let response = await axios({
                        url: '/license',
                        method: 'GET',
                        responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl,'_blank')
      }
    }
};
</script>

<style scoped>

.footer{
  position: relative;
  width:100%;
  bottom: 0;
}

.iconHover:hover {
  transition: ease-in 0.2s;
  color: #D70002;
}

.rowSeparator {
  margin-bottom: 15px;
}
</style>