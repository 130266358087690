<template>
        <v-sheet color="white" width="100%" height="100%">
            <v-container class="align-center justify-center">
                <v-row class="align-center justify-center">
                    <v-img contain max-width='500px'
          src='../../public/images/pratka_logo.svg'
          ></v-img>
                </v-row>
                <v-row class="align-center justify-center">
                    <h2>{{ $vuetify.lang.t("$vuetify.navigation.fedex") }}</h2>
                </v-row>
                <v-row class="align-center justify-center">
                    <v-img contain max-width='200px'
                        src='../assets/images/partners/fedex.jpg'
                        ></v-img>
                </v-row>
                <v-row class="align-center justify-center">
                    <v-btn color="primary" href="https://www.fedex.com/bg-bg/shipping/international.html">
                        {{ $vuetify.lang.t("$vuetify.navigation.menu.options.send") }}
                        <v-icon small right>mdi-menu-right</v-icon>
                    </v-btn>
                </v-row>
            </v-container>
        </v-sheet>
    <!-- <div>
        <home-hero-title></home-hero-title>
        <home-sites></home-sites>

        <v-container>
            <parcel-creation id="parcelCreation"></parcel-creation>
            <home-how-to></home-how-to>
            <home-reviews></home-reviews>
            <home-hero></home-hero>

            <home-courier-companies></home-courier-companies>
            <online-store></online-store>
        </v-container>
        <home-pricing></home-pricing>
        <ecommerce-pro></ecommerce-pro>
        <v-container>
        <home-about></home-about>
        </v-container>
        <home-questions></home-questions>
        <home-contacts></home-contacts>
    </div> -->
</template>

<script>
// // Relative Components
// import HomeHeroTitle from '@/components/home/HomeHeroTitle'
// import HomeSites from '@/components/home/HomeSites'
// import HomeHero from '@/components/home/HomeHero'
// import HomeCourierCompanies from '@/components/home/HomeCourierCompanies'
// import ParcelCreation from '@/components/ParcelCreation'
// // import OnlineStore from '@/components/home/new/OnlineStore.vue'
// import EcommercePro from '@/components/home/new/EcommercePro.vue'
// // 
// import HomeReviews from '@/components/home/HomeReviews'

// OLD
// import HomePricing from '@/components/home/HomePricing'
// import HomeAbout from '@/components/home/HomeAbout'
// import HomeQuestions from '@/components/home/HomeFAQ'
// import HomeContacts from '@/components/home/HomeContacts'
// // import HomeHowTo from '@/components/home/HomeHowTo'


// Node Modules

export default {
name: 'LandingPage',

components: {
    // HomeHeroTitle,
    // HomeSites,
    // // HomeHowTo,
    // HomeHero,
    // // HomePricing,
    // HomeReviews,
    // HomeAbout,
    // HomeQuestions,
    // HomeContacts,
    // HomeCourierCompanies,
    // ParcelCreation,
    // // OnlineStore,
    // EcommercePro
},

data() {
    return {
    cookieLaw_text_bg: 'Ние използваме бисквитки на нашия уебсайт, за да ви предоставим най-подходящото изживяване, като запомним вашите предпочитания и повтаряме посещенията. С натискането на „Приемам“ вие се съгласявате с използването на ВСИЧКИ бисквитки.',
    cookieLaw_btn_bg: 'Приемам',

    // Footer
    footer_icons: [
    { name:"facebook", icon:"mdi-facebook", href:"https://www.facebook.com/www.Pratka.net"},
    { name:"instagram", icon:"mdi-instagram", href:"https://www.instagram.com/pratkabg_com/"},
    ],
    }
},

methods: {
},

mounted() {
},
};
</script>
<style scoped>
h1 {
font-size: 2.3rem;
}

a {
font-size: 1.1rem;
color: gray;
}

a:hover {
transition: ease-in 0.2s;
color: #D70002;
}

.iconHover:hover {
transition: ease-in 0.2s;
color: #D70002;
}

.toolbar {
background-color: antiquewhite;
color:aliceblue;
}

</style>